import { graphql, navigate } from "gatsby";
import React, { useEffect, useMemo, useRef } from "react";
import Layout from "../containers/layout";
import { CollectionStyles } from "../styles/CollectionStyles";
import { ContactUs } from "../components/Homepage/ContactUs";
import {
  Carousel,
  Banner,
  CollectionFeaturedContent,
  CollectionCard,
  CollectionCardMobileView,
  GatsbySanityImage,
} from "../components";
import { getCollectionUrl } from "../lib/helpers";
import { useIsMobileLarge, usePageSectionsRef, useNavBar } from "../hooks";

import {
  LARGE_COLLECTION,
  WATER_VILLA_COLLECTION,
  BEACH_VILLA_COLLECTION,
  RESORT_COLLECTION,
  COUPLES_COLLECTION,
} from "../constants";

export const query = graphql`
  fragment Fragment_Villa on SanityVilla {
    _id
    name
    alternateName
    short_desc
    showers {
      option
      number
    }
    maxOccupancy {
      option
      number
    }
    villaPoolTypes {
      poolType
    }
    sizeSqm
    imageThumb {
      asset {
        gatsbyImageData(placeholder: BLURRED)
      }
      alt
    }
    price
    priceOnRequest
    numrooms

    resort {
      name
      shortName
      resortBrandLogo {
        asset {
          gatsbyImageData(placeholder: BLURRED)
        }
        alt
      }
    }
  }

  fragment Fragment_Featured_Villa on SanityFeaturedVilla {
    title
    villaone {
      name
      price
      short_desc
      tagline
      numrooms
      imageThumb {
        asset {
          gatsbyImageData(placeholder: BLURRED)
        }

        alt
      }
      headerImages {
        images {
          asset {
            gatsbyImageData(placeholder: BLURRED)
          }
          alt
        }
      }
      resort {
        name
        shortName
        resortBrandLogo {
          asset {
            gatsbyImageData(placeholder: BLURRED)
          }
          alt
        }
      }
    }
  }
  query CollectionTemplateQuery($id: String!, $isResortCollection: Boolean!) {
    pagesdata: sanityCollectionPage(id: { eq: $id }) {
      _id
      CollectionPageName
      slug {
        current
      }
      image {
        asset {
          gatsbyImageData(placeholder: BLURRED)
        }
        alt
      }
      beachVillaCollection @skip(if: $isResortCollection) {
        CollectionName
        active
        villas {
          ...Fragment_Villa
        }
        featuredvillas {
          ...Fragment_Featured_Villa
        }
      }
      waterVillaCollection @skip(if: $isResortCollection) {
        CollectionName
        active
        villas {
          ...Fragment_Villa
        }

        featuredvillas {
          ...Fragment_Featured_Villa
        }
      }

      banners {
        BannerTitle
        sectionHeroImage {
          asset {
            gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
          }
          alt
        }
        sectionIconBadge {
          asset {
            gatsbyImageData(placeholder: BLURRED)
          }
          alt
        }
        descriptionShort
        bannerSize
      }
      resortCollections @include(if: $isResortCollection) {
        CollectionName
        active
        resorts {
          shortName
          active
          name
          image {
            asset {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
          locationAtoll
          numberOfBars
          numberOfRestaurants
          numberOfRooms
          resortTransferType {
            transferType
          }
          timeToAirport
          resortBrandLogo {
            asset {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        featuredResorts {
          shortName
          name
          image {
            asset {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
          resortBrandLogo {
            asset {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
    }

    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      contactUs {
        address
        email
        phoneOne
        contactPeople {
          name
          image {
            ...SanityImage
            alt
          }
        }
        hours {
          days
          hours
        }
        businessHoursDescription
      }
    }
  }
`;
const pageSections = [
  {
    name: WATER_VILLA_COLLECTION,
    onClick: () => navigate("../top-water-villas/"),
    isDropDown: false,
  },
  {
    name: BEACH_VILLA_COLLECTION,
    onClick: () => navigate("../beach-villas/"),
    isDropDown: false,
  },
  {
    name: LARGE_COLLECTION,
    onClick: () => navigate("../large-villas/"),
    isDropDown: false,
  },
  {
    name: RESORT_COLLECTION,
    onClick: () => navigate("../resort-collection/"),
    isDropDown: false,
  },
  {
    name: COUPLES_COLLECTION,
    onClick: () => navigate("../couples-collection"),
    isDropDown: false,
  },
];

const CollectionTemplate = (props) => {
  const { data } = props;
  const collections = data && data.pagesdata;
  const isResortCollection = collections?.resortCollections;

  const resortCollections = collections?.resortCollections;

  const allCollections =
    isResortCollection && resortCollections?.length
      ? resortCollections
      : collections?.beachVillaCollection?.length
      ? collections.beachVillaCollection
      : collections?.waterVillaCollection?.length
      ? collections?.waterVillaCollection
      : [];

  const banners = collections?.banners;
  const slug = collections?.slug;
  const pathName = getCollectionUrl({ slug: slug });
  const site = data && data.site;

  const isMobileLarge = useIsMobileLarge();
  const { setNavLinks } = useNavBar();
  const heroRef = useRef(null);

  const navigationState = {
    pageFrom: "collection",
    collectionPage: {
      url: pathName,
      collectionName: collections.CollectionPageName,
    },
  };

  const activeCollections = allCollections?.filter(({ active }) => !!active);
  const collections__ = useMemo(() => {
    const collections_ = activeCollections?.map(({ CollectionName }) => {
      return {
        name: CollectionName,
      };
    });
    return collections_;
  }, []);

  const pageSections_ = useMemo(() => {
    return pageSections.map((section) => {
      if (section?.name == collections?.CollectionPageName) {
        return {
          ...section,
          isDropDown: true,
          visibleOnMobile: true,
          content: collections__,
        };
      }
      return section;
    });
  }, [collections__.length]);

  const { navLinks } = usePageSectionsRef(pageSections_);

  useEffect(() => {
    setNavLinks(navLinks);
  }, [navLinks?.length]);

  return (
    <Layout>
      <CollectionStyles>
        <h1 className="collectionpage_title">
          {collections.CollectionPageName}
        </h1>
        {collections.image && (
          <div className="collection__image_hero" ref={heroRef}>
            {collections.image && collections.image.asset && (
              <GatsbySanityImage
                gatsbyImage={collections?.image?.asset?.gatsbyImageData}
              />
            )}
          </div>
        )}

        <div className="collection_container">
          {activeCollections?.map(
            (
              {
                villas,
                resorts: resorts_,
                CollectionName,
                featuredvillas,
                featuredResorts,
              },
              collectionNumber
            ) => {
              const activeResorts = resorts_?.filter(({ active }) => !!active);
              return (
                <div
                  key={CollectionName}
                  id={CollectionName}
                  className="mastercol"
                  // ref={contentRefs?.current[CollectionName]}
                >
                  <h3 className="col_name">{CollectionName}</h3>
                  {isResortCollection ? (
                    isMobileLarge && collectionNumber !== 0 ? (
                      activeResorts?.length && (
                        <Carousel
                          className="carousel"
                          slidesToShow={1}
                          dots={false}
                        >
                          {activeResorts?.map((resort, index) => {
                            return (
                              <CollectionCardMobileView
                                listKey={`${resort._id} ${index}`}
                                isResort
                                content={resort}
                                navigationState={navigationState}
                              />
                            );
                          })}
                        </Carousel>
                      )
                    ) : (
                      <ul className="collection_wrap">
                        {activeResorts?.map((resort) => (
                          <CollectionCard
                            content={resort}
                            listKey={`${resort._id} ${collectionNumber}`}
                            isResort
                            navigationState={navigationState}
                            wrapperClassName="collection_wrap_item"
                          />
                        ))}
                      </ul>
                    )
                  ) : isMobileLarge ? (
                    villas?.length && (
                      <Carousel
                        className="carousel"
                        slidesToShow={1}
                        dots={false}
                      >
                        {villas?.map((villa, index) => {
                          return (
                            <CollectionCardMobileView
                              listKey={`${villa._id} ${index}`}
                              content={villa}
                              navigationState={navigationState}
                            />
                          );
                        })}
                      </Carousel>
                    )
                  ) : (
                    <ul className="collection_wrap">
                      {villas?.length &&
                        villas?.map((villa, index) => {
                          return (
                            <CollectionCard
                              content={villa}
                              listKey={`${villa._id} ${index}`}
                              navigationState={navigationState}
                              wrapperClassName="collection_wrap_item"
                            />
                          );
                        })}
                    </ul>
                  )}
                  <CollectionFeaturedContent
                    content={featuredvillas || featuredResorts || []}
                    isResort={featuredResorts?.length}
                  />
                  {collectionNumber === 1 || collectionNumber === 2 ? (
                    <Banner
                      collectionNumber={collectionNumber}
                      styles={{ marginTop: "4rem" }}
                      banner={
                        banners.filter(
                          (__, index) => index === collectionNumber
                        )[0]
                      }
                    />
                  ) : null}
                </div>
              );
            }
          )}
        </div>
        <ContactUs contactUs={site.contactUs} />
      </CollectionStyles>
    </Layout>
  );
};

export default CollectionTemplate;
